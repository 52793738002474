import React from 'react';
//import Head from 'next/head';
import Script from 'next/script';
import { AppProps } from 'next/app';
import { useCookie, useUserLocation } from '@/hooks';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Footer } from '@/components/footer';
import * as Cronitor from '@cronitorio/cronitor-rum-js';
import { SplashLoader } from '@/components/splash-loader';
import Loader from '@/components/box-loader';
import { CookiePrompt } from '@/components/cookie-prompt';
import { config } from '@fortawesome/fontawesome-svg-core';
import { useCronitor } from '@cronitorio/cronitor-rum-nextjs';
import * as fbq from '../lib/fbpixel';
// import { ContactBtnFloating } from '@/components/contact-btn-floating';
import DownloadFooter from '@/components/DownloadFooter';
import '@/styles/global.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/900.css';
import 'react-modal-video/scss/modal-video.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { SearchProvider } from '@/contexts/searchContext';

config.autoAddCss = false;

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function LevelApp({ Component, pageProps }: AppPropsWithLayout) {
  const cookies = useCookie();
  const router = useRouter();
  const [showLoader, setShowLoader] = React.useState(true);

  React.useEffect(() => {
    const handleLoad = () => {
      setShowLoader(false);
    };
    window.addEventListener(`load`, handleLoad);
    return () => {
      window.removeEventListener(`load`, handleLoad);
    };
  }, []);
  const getLayout = Component.getLayout ?? ((page) => page);

  // load cronitor for measuring app performance
  useCronitor(`bb6b59f626c3a36cf4a696263cd57e7f`, {
    scriptSrc: `/c4oni1o4-script.js`,
    honorDNT: true,
    includeURLFragment: true,
  });

  React.useEffect(() => {
    const scripts = [
      document.createElement(`script`),
      document.createElement(`script`),
    ];

    scripts[0].src = `https://www.googletagmanager.com/gtag/js?id=G-654FM786XV`;
    scripts[1].innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('set', { 'cookie_flags': 'SameSite=None;Secure' });

      gtag('config', 'G-654FM786XV');
    `;

    document.querySelector(`head`)?.appendChild(scripts[0]);
    document.querySelector(`head`)?.appendChild(scripts[1]);

    return () => {
      document.querySelector(`head`)?.removeChild(scripts[0]);
      document.querySelector(`head`)?.removeChild(scripts[1]);
    };
  }, [cookies]);

  React.useEffect(() => {
    window.document.onreadystatechange = () => {
      if (
        document.readyState === `complete` ||
        document.readyState === `interactive`
      ) {
        setShowLoader(false);
      }
    };

    const timeout = setTimeout(() => setShowLoader(false), 4000);

    //if (window.location.pathname.startsWith(`/user`)) {
    //setShowLoader(() => false);
    //} else {
    //setShowLoader(() => true);
    //}

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const { isIndia, isLoading } = useUserLocation();

  React.useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)

    if (isLoading) {
      return;
    }
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on(`routeChangeComplete`, handleRouteChange);
    return () => {
      router.events.off(`routeChangeComplete`, handleRouteChange);
    };
  }, [router.events, isLoading]);

  return getLayout(
    <>
      <Head>
        <meta
          name="google-adsense-account"
          content="ca-pub-3436497941634374"
        ></meta>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5KZKFRB');
          `,
          }}
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3436497941634374"
          crossOrigin="anonymous"
        ></script>

        <script
          dangerouslySetInnerHTML={{
            __html: `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "n9svsi7zmq");
`,
          }}
        />

        {!isLoading && isIndia && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '500207002676577');
fbq('track', 'PageView');
`,
            }}
          />
        )}

        {!isLoading && !isIndia && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '798889752405987');
fbq('track', 'PageView');
`,
            }}
          />
        )}
      </Head>
      {/* <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      /> */}
      <Script
        id="ld-json"
        type="application/ld+json"
        strategy="beforeInteractive"
      >
        {`
        {
  "@context": "https://schema.org/",
  "@type": "Organization",
  "url": "http://level.game",
  "logo": "http://level.game/logo.png",
  "name": "Level SuperMind",
  "image": "http://level.game/logo.png",
  "email": "",
  "description": "Level Fittech Private Limited, working as Level SuperMind, is a mind performance app based out of India, with its headquarters in Mumbai, founded by Harshil Karia, Aayush Anand and Ranveer Allahbadia. Recently crowned as the App of the Year, 2023 (India), Level SuperMind aims to help people reduce overthinking, stress and anxiety and improve focus, sleep, creativity and emotional intelligence through its neuroscience backed activities including meditations available not just in English but also regional languages, daily workout plans, positive affirmations, wisdom stories, sleep stories, reflective journals, focus and sleep music, breathwork, step tracker. Level SuperMind has additional features like AI personal coach, streak leaderboard and reminder feature which are essential for users’ habit building.  These methods are scientifically proven to enhance mental wellness and are crafted to cater to the diverse needs and preferences of the users. The challenges of a fast-paced modern life and relentless pressure can affect anyone, regardless of age, which is why the app is designed to be universally accessible and effective for all within the target age group.  Awards & Recognition - Google Play Awards: Best App in India and Best for Personal Growth.- Product Hunt: Product of the Day 2022 (Top 4). - Golden Kitty Award: Finalist in 2023. - Indian App Scale Academy 2023: Top 15. - Ranked among the top ten trending apps on iOS and Android in December 2022",
  "address": "Office No. 313, 3rd Floor, C Wing, Trade World Kamala Mills Compound, Senapati Bapat Marg, Lower Parel, Mumbai, Maharashtra 400013",
  "telephone": "+91 9082757997",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.6",
    "ratingCount": "13000"
  },
  "award": "Best App, 2023 India| Golden Kitty Finalist, 2023| Product of the Day, 2022 (Top 4)",
  "founder": [
    {
      "@type": "Person",
      "name": "Harshil Karia",
      "image": "https://drive.google.com/file/d/16efG05bIW3oGUAqXjGlpTEOtcO3tkQlr/view?usp=drive_link",
      "url": "https://drive.google.com/file/d/16efG05bIW3oGUAqXjGlpTEOtcO3tkQlr/view?usp=drive_link",
      "jobTitle": "Co-Founder",
      "email": "hk@level.game",
      "gender": "Male",
      "birthDate": "1987-08-21T00:00:00.000Z",
      "award": "First Indian to be Awarded Global Founder of the Year by Founders Alliance"
    },
    {
      "@type": "Person",
      "name": "Aayush Anand",
      "image": "https://drive.google.com/file/d/1HMWdGjGXXXwzj6x3eBwZcATOkb0S_PgS/view?usp=drive_link",
      "url": "https://linktr.ee/aayushmisfiit?fbclid=PAAaZeJdB-aDf3MnGJLlqSmKNSfjmx2bsH1OAXH2s3FxhbLGwHVYbNEbmZJ2g",
      "jobTitle": "Co-Founder",
      "gender": "Male",
      "birthDate": "1997-04-09T18:30:00.000Z",
      "award": "Built India's Best app of 2023 (India) by Google"
    },
    {
      "@type": "Person",
      "name": "Ranveer Allahbadia",
      "image": "https://drive.google.com/file/d/16Ue3MWOIKKJ4n5SusNxEQm4qNIdyLVSm/view?usp=drive_link",
      "url": "https://www.linkedin.com/in/beerbiceps?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B7JAPHkQ%2BRjuswYqTHk4M2A%3D%3D",
      "jobTitle": "Co-Founder",
      "gender": "Male",
      "birthDate": "1993-02-06T18:30:00.000Z",
      "award": "Young Entrepreneur Award 2020 - Business World Youth Influencer of the Year 2019 MTV - IWM Digital Awards Best Fashion Influencer 2019 - Cosmopolitan\nImpact 30 under 30 (2020) - Exchange4Media group PYF International Youth Inspiration of the Year Award 2020"
    }
  ],
  "foundingDate": "2021-07-16T00:00:00.000Z",
  "foundingLocation": {
    "@type": "Place",
    "name": "Mumbai",
    "slogan": "SUPERCHARGE YOUR MIND"
  },
  "legalName": "Level Fittech Private Limited",
  "location": {
    "@type": "Place",
    "name": "Mumbai",
    "slogan": ""
  },
  "numberOfEmployees": {
    "@type": "QuantitativeValue",
    "unitText": "10-40"
  },
  "parentOrganization": {
    "@type": "Organization",
    "name": "Level Fittech Private Limited"
  },
  "slogan": "Supercharge Your Mind",
  "sameAs": [
    "https://www.facebook.com/leveldotgame/",
    "https://instagram.com/levelsupermind",
    "https://www.youtube.com/@Level.Supermind",
    "https://www.linkedin.com/company/levelsupermind",
    "https://x.com/LevelSupermind",
    "https://pinterest.com/growwithlevel/",
    "https://level.game/"
  ]
}
`}
      </Script>
      {/* <Script
        async
        src={'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js??client=ca-pub-3436497941634374'}
        strategy="lazyOnload"
        crossOrigin="anonymous"
      /> */}

      <SearchProvider
        searchBlog={async (query: string) => {
          // Implement a default searchBlog function here
          console.log(`Searching for:`, query);
          // You can return an empty array or implement a basic search
          return [];
        }}
      >
        <body>
          {/* Second part of the Google Tag Manager code */}
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5KZKFRB"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
            }}
          />
          {/* Rest of your LevelApp component */}
          {/* {showLoader && (
          <div
            style={{ zIndex: 2000, backgroundColor: `rgba(0, 0, 0, 0.5)` }}
            className="fixed top-0 left-0 bottom-0 right-0 bg-white"
          >
            <Loader />
          </div>
        )} */}
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <Component {...pageProps} />

          {/* when rendering in levelapp webview, website must not load footer */}
          {router.query.source !== `levelapp` &&
            !router.pathname.startsWith(`/user`) &&
            !router.pathname.startsWith(`/top-meditators`) &&
            !router.pathname.startsWith(`/wrapped`) && <Footer />}

          <CookiePrompt />
          {/* <ContactBtnFloating /> */}
          <DownloadFooter />
        </body>
      </SearchProvider>
    </>,
  );
}

{
  /* <script
dangerouslySetInnerHTML={{
  __html: `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '500207002676577');
fbq('track', 'PageView');
`,
}}
/> */
}
